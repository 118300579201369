import React from 'react'
import Typography from './Typography'
import { IoLogoGithub, IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io5'
import { Link } from 'react-router-dom'

const AboutMe = () => {
  return (
    <div className='w-full min-h-[25rem] max-h-[45rem] flex justify-center'>
        <div className='w-3/4 h-full flex flex-col items-center justify-center gap-2'>
            <img
                src='assets/images/avatar.JPG'
                alt=''
                className='min-w-[5rem] max-w-[10rem] md:min-w-[5rem] md:max-w-[15rem] rounded-tr-full rounded-tl-full'
            />
            <Typography variant='subheading' className='text-center'>Dimas Gusti</Typography>
            <Typography variant='caption' className='text-gray-600 text-center'>Full Stack Developer from Indonesia</Typography>
            <Typography variant='caption' className='text-gray-600 text-center'>Currently working on <a href='https://linkku-six.vercel.app' target='_blank' rel='noreferrer' className='underline underline-offset-2 text-black'>Linkku</a>. Wait for the updates!</Typography>
            <Typography variant='small' className='text-gray-600 text-center'>Contact</Typography>
            <div className='flex flex-row justify-center items-center gap-2'>
                <Link to='https://linkedin.com/in/gustidimas' target='_blank'>
                    <IoLogoLinkedin size={30} className='transition duration-200 ease-in-out text-gray-500 hover:text-black' />
                </Link>
                <Link to='https://github.com/dimasgusti' target='_blank'>
                    <IoLogoGithub size={30} className='transition duration-200 ease-in-out text-gray-500 hover:text-black'/>
                </Link>
                <Link to='https://instagram.com/dimsgusti' target='_blank'>
                    <IoLogoInstagram size={30} className='transition duration-200 ease-in-out text-gray-500 hover:text-black'/>
                </Link>
            </div>
            <a href="/assets/pdf/GustiDimasNovarossi_CV_Academy2025.pdf" download>
                <Typography variant='button'>Download CV</Typography>
            </a>
        </div>
    </div>
  )
}

export default AboutMe